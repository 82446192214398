import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { AnalyticsProvider } from './components/AnalyticsProvider';
import { Navbar } from './components/Navbar';
import { Home } from './pages/Home';
import { Strategies } from './pages/Strategies';
import { Contact } from './pages/Contact';
import { Auth } from './pages/Auth';
import { Feedback } from './pages/Feedback';
import { initGoogleAnalytics } from './lib/analytics/gtag';

export default function App() {
  useEffect(() => {
    const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
    if (measurementId) {
      initGoogleAnalytics(measurementId);
    }
  }, []);

  return (
    <AuthProvider>
      <Router>
        <AnalyticsProvider>
          <div className="min-h-screen bg-gray-50">
            <Navbar />
            <div className="pt-16">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/strategies" element={<Strategies />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Auth />} />
                <Route path="/feedback" element={<Feedback />} />
              </Routes>
            </div>
          </div>
        </AnalyticsProvider>
      </Router>
    </AuthProvider>
  );
}