import React from 'react';
import { Link } from 'react-router-dom';
import { TrendingUp } from 'lucide-react';

export function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
        <div className="text-center">
          <TrendingUp className="h-16 w-16 text-indigo-600 mx-auto" />
          <h1 className="mt-4 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Algo Trading Strategies
          </h1>
          <h2 className="mt-4 text-xl text-gray-500">
            by Kunal
          </h2>
          <div className="mt-10 flex justify-center gap-4">
            <Link
              to="/strategies"
              className="bg-indigo-600 text-white px-8 py-3 rounded-md text-lg font-medium hover:bg-indigo-700"
            >
              View Strategies
            </Link>
            <Link
              to="/login"
              className="bg-white text-indigo-600 border-2 border-indigo-600 px-8 py-3 rounded-md text-lg font-medium hover:bg-indigo-50"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}