import React from 'react';
import { supabase } from '../lib/supabase';

export function SocialLogin() {
  const handleGoogleLogin = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/feedback`,
      },
    });
  };

  return (
    <div className="mt-6">
      <button
        onClick={handleGoogleLogin}
        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <img
          className="h-5 w-5 mr-2"
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
          alt="Google logo"
        />
        Continue with Google
      </button>
    </div>
  );
}