import React from 'react';
import { Mail, Linkedin, MessageSquare } from 'lucide-react';

export function Contact() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Get in Touch
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Feel free to reach out through any of these channels
          </p>
        </div>

        <div className="mt-12 max-w-lg mx-auto grid gap-8">
          <a
            href="mailto:Kunal.95a@gmail.com"
            className="flex items-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <Mail className="h-8 w-8 text-indigo-600" />
            <div className="ml-4">
              <h3 className="text-xl font-medium text-gray-900">Email</h3>
              <p className="mt-1 text-gray-500">Kunal.95a@gmail.com</p>
            </div>
          </a>

          <a
            href="https://www.linkedin.com/in/kunal35/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <Linkedin className="h-8 w-8 text-indigo-600" />
            <div className="ml-4">
              <h3 className="text-xl font-medium text-gray-900">LinkedIn</h3>
              <p className="mt-1 text-gray-500">Connect with me professionally</p>
            </div>
          </a>

          <a
            href="https://api.whatsapp.com/send?phone=+919553324251&text=Hi"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <MessageSquare className="h-8 w-8 text-indigo-600" />
            <div className="ml-4">
              <h3 className="text-xl font-medium text-gray-900">WhatsApp</h3>
              <p className="mt-1 text-gray-500">Chat with me directly</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}