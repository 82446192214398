import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ExternalLink } from 'lucide-react';

const mockData = [
  { date: '2024-01', value: 10000 },
  { date: '2024-02', value: 12000 },
  { date: '2024-03', value: 11500 },
  { date: '2024-04', value: 13500 },
  { date: '2024-05', value: 15000 },
];

export function Strategies() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900">Live Trading Strategies</h2>
          <p className="mt-4 text-lg text-gray-500">
            Track the performance of our active trading strategies
          </p>
        </div>

        <div className="mt-8">
          <a
            href="https://console.zerodha.com/verified/38b21b04"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            View Verified Zerodha PNL
            <ExternalLink className="ml-2 h-4 w-4" />
          </a>
        </div>

        <div className="mt-8 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900">Mean Reversion Strategy</h3>
              <dl className="mt-4 grid grid-cols-2 gap-4">
                <div>
                  <dt className="text-sm font-medium text-gray-500">Deployed Since</dt>
                  <dd className="mt-1 text-lg font-semibold text-gray-900">Jan 2024</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Total PNL</dt>
                  <dd className="mt-1 text-lg font-semibold text-green-600">+₹50,000</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Capital</dt>
                  <dd className="mt-1 text-lg font-semibold text-gray-900">₹10,00,000</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Return</dt>
                  <dd className="mt-1 text-lg font-semibold text-green-600">+5%</dd>
                </div>
              </dl>
              <div className="mt-6 h-48">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={mockData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#4F46E5" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Add more strategy cards as needed */}
        </div>
      </div>
    </div>
  );
}